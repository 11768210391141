var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    _vm._g(
      _vm._b({ style: _vm.styles }, "v-card", _vm.$attrs, false),
      _vm.$listeners
    ),
    [
      _vm.hasOffset
        ? _c(
            "helper-offset",
            {
              attrs: {
                inline: _vm.inline,
                "full-width": _vm.fullWidth,
                offset: _vm.offset,
              },
            },
            [
              !_vm.$slots.offset
                ? _c(
                    "v-card",
                    {
                      staticClass:
                        "v-card--material__header d-flex align-center",
                      attrs: {
                        color: _vm.color,
                        to: _vm.titleLink,
                        elevation: _vm.elevation,
                        dark: "",
                        "min-height": "80",
                      },
                    },
                    [
                      !_vm.title && !_vm.text
                        ? _vm._t("header")
                        : _c("div", { staticClass: "px-3" }, [
                            _c("h4", {
                              staticClass: "title font-weight-light mb-2",
                              domProps: { textContent: _vm._s(_vm.title) },
                            }),
                            _c("p", {
                              staticClass: "category font-weight-thin mb-0",
                              domProps: { textContent: _vm._s(_vm.text) },
                            }),
                          ]),
                    ],
                    2
                  )
                : _vm._t("offset"),
            ],
            2
          )
        : _vm._e(),
      _c("v-card-text", [_vm._t("default")], 2),
      _vm.$slots.actions ? _c("v-divider", { staticClass: "mx-3" }) : _vm._e(),
      _vm.$slots.actions
        ? _c("v-card-actions", [_vm._t("actions")], 2)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }