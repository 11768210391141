var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    _vm._g(
      _vm._b(
        { staticClass: "v-card--material-title", style: _vm.styles },
        "v-card",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.isOffset
        ? _c(
            "helper-offset",
            { attrs: { "full-width": _vm.fullWidth, offset: _vm.os } },
            [
              _vm._t("offset", [
                _c("div", { staticClass: "offset-template" }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "inline-block",
                        height: "36px",
                        "line-height": "36px",
                        "font-weight": "bold",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.titleText) + "\n        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
      _c("div", { style: _vm.textStyles }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }