var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    _vm._g(
      _vm._b(
        {
          attrs: { elevation: _vm.elevation, value: _vm.value, dark: _vm.dark },
        },
        "v-alert",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }