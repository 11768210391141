import Vue from 'vue';
import Router from 'vue-router';
import { logout } from '@/module/service/oAuth';
import BSS from './bss';
import OSS from './oss';
import CMS from './cms';
import DBM from './dbm';
import BMR from './bmr';
import { permission } from '../permission';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    permission['menu.bss'] && BSS,
    permission['menu.oss'] && OSS,
    permission['menu.cms'] && CMS,
    permission['menu.dbm'] && DBM,
    permission['menu.bmr'] && BMR,
    {
      path: '/',
      redirect: '/business/dashboard'
    }
  ].filter((o) => o),
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  to.path === '/logout' ? logout() : next();
});

export default router;
